// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~@eonasdan/tempus-dominus/dist/css/tempus-dominus.min.css";

@import "main";

@import "user_profile_card";

