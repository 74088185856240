/*@import url(https://fonts.googleapis.com/css?family=Lato:400,300,700);*/
//@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@import "trumbowyg/dist/ui/trumbowyg.min.css";

//Import Trumbowyg plugins
@import "trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css";
@import "trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.min.css";
@import "trumbowyg/dist/plugins/table/ui/trumbowyg.table.min.css";

html,
body {
    margin:0;
    padding:0;
    height:100%;
    max-width: 100%;
    /*background: #43423E;*/
    background-color: black;
    background-image: url("/image_assets/curtain_mid.jpg");
    background-repeat: repeat-x;
    font-family: Arial, sans-serif;
    font-size: 1em;
}

img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
}

.navbar {
    font-size: 1.3em;
    font-family: 'trashhand';
}

a {
    color: black;
    text-decoration: none;
}

a:hover {
    color: #666;
    text-decoration: none;
}

#container {
    min-height:100%;
    max-width: 100%;
    position:relative;
}

form .btn {
    margin: 1rem;
    margin-right: 0;
    margin-left: 0;
}

form .btn_no_margin {
    margin: unset !important;
}

/*.form-row {
    #margin: 1em;
}*/

#content {
    #margin-left: 3%;
    #margin-right: 3%;
    padding: 10px;
}

.reset-font {
    font-size: revert;
    font-family: Arial, sans-serif
}

h1 {
    font-size: 60px;
    font-family: 'trashhand';
}

h2 {
    font-size: 45px;
    font-family: 'trashhand';
}

h3 {
    font-size: 18px;
    font-family: 'trashhand';
}

h4 {
    font-size: 16px;
    font-family: 'trashhand';
}

h5 {
    font-size: 13px;
    font-family: 'trashhand';
}

h6 {
    font-size: 15px;
    font-family: 'trashhand';
}

#content img {
    max-width: 100%;
}

.mitglied_img {
    max-height: 150px;
    object-fit: cover;
}

#UserImage img {
    height: 350px;
    width: 350px;
    border-radius: 50%;
    object-fit: cover;
}

#memberAdministration {
    margin: 1em;
}

trashhand-webfont.woff div.edit#content {
    padding: 0;
    margin: 0;
    float: left;
    width: 79%;
    height: 100%;
}

.imgHover:hover {
    -moz-box-shadow: 0 0 10px #ccc;
    -webkit-box-shadow: 0 0 10px #ccc;
    box-shadow: 0 0 10px #ccc;
}

p.edit {
    display: none;
    width: 100%;
}

input.edit {
    display: none;
}

form.hidden {
    margin-top: 0;
}

#ElementNotEditable {
    display: none;
}

#header_img_container img {
    width: 100%;
}

#container_content {
    width: 100%;
    min-height: 300px;
    background-color: black;
    color: white;
    /*margin-top: 2%;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;*/
}

.bg-white {
    color: black;
}

img#ueber_uns_img {
    border: 5px solid #FFFFFF;
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%
}

.footer {
    /*position: absolute;*/
    right: 0;
    bottom: 0;
    left: 0;
}

.spacer {
    height: 25%;
    /* margin: 20px 0 -50px 0; use this if you want #container to have a 'bottom padding', in this case of 20px */
    background: transparent; /* you'll need this if #container's parent element has a different background from #container itself */
}

@font-face {
    font-family: "TrashHand";
    src: url('/fonts/trashhand-webfont.woff') format("woff");
}

#first_row {
    /*max-height: 600px;*/
}

#first_left {
    position: relative;
}

#first_right {
    display: inline-grid;
}

.content-center {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.text-padding-left {
    padding-left: 2em;
}

.custom-row {
    min-height: 400px;
    margin-left: 0;
    margin-right: 0;
}

#third_row {
    min-height: 70px !important;
    text-align: center;
}

#third_row a {
    color: black;
    text-decoration: none;
}

#third_row a:hover {
    color: #666;
}

.poster {
    height: auto;
    max-width: 17em;
    background-color: #6c757d !important;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    overflow: hidden;
}

.poster:hover {
    box-shadow: none !important;
}

.poster img {
    z-index: -1;
    max-height: 100%;
    object-fit: cover;
    margin-top: -25px;
}

#social_fb a {
    color: black;
    text-decoration: none;
}

#social_insta img {
    width: 2.5em;
}

.fa-facebook-square {
    color: #4267B2;
    text-decoration: none;
}

.fa-facebook-square:hover {
    /*color: #34518D;*/
    text-decoration: none;
}

.fa-instagram {
    color: #FD1D1D;
    text-decoration: none;
}

.fa-instagram:hover {
    color: #CC0202;
    text-decoration: none;
}

.carousel-inner img {
    height: 400px;
}

.carousel-inner {
    width: 100%;
    max-height: 400px !important;
}

#natürlich-blond-poster {
    #margin-top: -25px;
}

#main_logo {
    text-align: center;
}

.logo {
    max-height: 300px;
    width: auto;
    max-width: 100%;
}

@media screen and (max-width: 991px) {
    .no_mobile_wrap {
        overflow: scroll;
        flex-wrap: nowrap !important;
        justify-content: flex-start !important;
    }
}

@media screen and (max-width: 768px) {

    .container {
        min-width: unset;
    }

    .content-center {
        padding-top: 5px;
    }

    #container_content {
        width: 100%;
    }

    #first_row {
        max-height: none;
    }

    .row {
        height: auto;
    }

    .personen {
        justify-content: unset !important;
    }

    .mitglied {
        margin-left: auto;
        margin-right: auto;
    }

    .personen {
        margin-left: auto;
        margin-right: auto;
    }

    #vorstand {
        margin-left: unset;
    }

}

#Musical Detail *,
*:before,
*:after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: #5C7FB8
}

a:hover {
    text-decoration: underline;
}

/*a.card-link {
    color: black;
    text-decoration: none;
}*/

.card-shadow {
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
}

.reset-this-font {
    font-family: initial !important;
}

.admin-card {
    width: 220px;
    height: 220px;

    margin-left: 2em;
    margin-bottom: 1em;
}

.admin-card:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.08);
}


/*Movie Card New*/
/*.movie_list {
	margin-top: 5rem;
	padding-left: 4rem !important;
}*/
.card-view {
    background: #ffffff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 280px;
    min-width: 280px;
    margin: 2em;
    border-radius: 10px;
    display: inline-block;
    padding: unset;
}


.musicalcard-header {
    padding: 0;
    margin: 0;
    height: 367px;
    width: 100%;
    display: block;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-header-icon {
    position: relative;
}

.header-icon {
    width: 100%;
    height: 367px;
    line-height: 367px;
    text-align: center;
    vertical-align: middle;
    margin: 0 auto;
    color: #ffffff;
    font-size: 54px;
    text-shadow: 0px 0px 20px #6abcea, 0px 5px 20px #6ABCEA;
    opacity: .85;
}

.header-icon:hover {
    background: rgba(0, 0, 0, 0.15);
    font-size: 74px;
    text-shadow: 0px 0px 20px #6abcea, 0px 5px 30px #6ABCEA;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    opacity: 1;
}

.card-view:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.08);
}

.card-movie-content {
    padding: 18px 18px 24px 18px;
    margin: 0;
}

.card-movie-content-head,
.card-movie-info {
    display: table;
    width: 100%;
}

.card-movie-title {
    font-size: 22px;
    margin: 0;
    display: table-cell;
}

h1.card-movie-title,
h2.card-movie-title,
h3.card-movie-title,
h4.card-movie-title,
h5.card-movie-title,
h6.card-movie-title {
    /*font: normal;*/
    font-family: sans-serif !important;
    color: black;
}

card-movie-content a:hover {
    color: #666;
    text-decoration: none !important;
}

.ratings {
    width: 50px;
    height: 15px;
    background-size: contain;
    display: table-cell;
    text-align: right;
    position: relative;
    margin-top: 5px;
    font-weight: 600;
}

.card-movie-info {
    margin-top: 1em;
}

.movie-running-time {
    display: table-cell;
    text-transform: uppercase;
    text-align: center;
}

.movie-running-time:first-of-type {
    text-align: left;
}

.movie-running-time:last-of-type {
    text-align: right;
}

.movie-running-time label {
    display: block;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: .5em;
    font-size: 9px;
}

.movie-running-time span {
    font-weight: 700;
    font-size: 11px;
}

/*Movie Card Old*/
.movie-card {
    font: 14px/22px "Lato", Arial, sans-serif;
    color: #A9A8A3;
    padding: 40px 0;
}

.container {
    margin-top: 2%;
    margin-bottom: 5%;
    padding: 2%;
    background: white;
    /*background: #F0F0ED;*/
    position: relative;
    overflow: hidden;
}

.no_background_color {
    background-color: unset !important;
}

.container_no_padding {
    padding: unset;
}

/*
.hero {
  height: 342px;
  margin:0;
  position: relative;
  overflow: hidden;
  z-index:1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

}

.hero:before {
  content:'';
  width:100%; height:100%;
  position:absolute;
  overflow: hidden;
  top:0; left:0;
  background:red;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/hobbit_bg.jpg");
  z-index:-1;

  transform: skewY(-2.2deg);
  transform-origin:0 0;

  #chrome antialias fix
  -webkit-backface-visibility: hidden;

}
*/
.cover {
    position: absolute;
    top: 160px;
    left: 40px;
    z-index: 2;
}

.details {
    padding: 190px 0 0 280px;
}

.details .title1 {
    color: white;
    font-size: 44px;
    margin-bottom: 13px;
    position: relative;
}
.details span {
    position: absolute;
    top: 3px;
    margin-left: 12px;
    background: #C4AF3D;
    border-radius: 5px;
    color: #544C21;
    font-size: 14px;
    padding: 0px 4px;
}


.details .title2 {
    color: #C7C1BA;
    font-size: 23px;
    font-weight: 300;
    margin-bottom: 15px;
}

.details .likes {
    margin-left: 24px;
}

.details .likes:before {
    content: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/icon_like.png");
    position: relative;
    top: 2px;
    padding-right: 7px;
}

.description {
    bottom: 0px;
    height: 200px;
    font-size: 16px;
    line-height: 26px;
    color: #B1B0AC;
}

.column1 {
    padding-left: 50px;
    padding-top: 120px;
    width: 220px;
    float: left;
    text-align: center;
}

.tag {
    background: white;
    border-radius: 10px;
    padding: 3px 8px;
    font-size: 14px;
    margin-right: 4px;
    line-height: 35px;
    cursor: pointer;
}

.tag:hover {
    background: #ddd;
}

.column2 {
    padding-left: 41px;
    padding-top: 30px;
    margin-left: 20px;
    width: 480px;
    float: left;
}

.avatars {
    margin-top: 23px;
}

.avatars img {
    cursor: pointer;
}

.avatars img:hover {
    opacity: 0.6;
}

.avatars a:hover {
    text-decoration: none;
}

fieldset,
label {
    margin: 0;
    padding: 0;
}

/****** Style Star Rating Widget *****/
.rating {
    border: none;
    float: left;
}

.rating > input {
    display: none;
}

.rating > label:before {
    margin: 5px;
    margin-top: 0;
    font-size: 1em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating > .half:before {
    content: "\f089";
    position: absolute;
}

.rating > label {
    color: #ddd;
    float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/
.rating > input:checked ~ label,
    /* show gold star when clicked */
.rating:not(:checked) > label:hover,
    /* hover current star */
.rating:not(:checked) > label:hover ~ label {
    color: #FFD700;
}

/* hover previous stars in list */
.rating > input:checked + label:hover,
    /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
    /* lighten current selection */
.rating > input:checked ~ label:hover ~ label {
    color: #FFED85;
}

a[data-tooltip] {
    position: relative;
}

a[data-tooltip]::before,
a[data-tooltip]::after {
    position: absolute;
    display: none;
    opacity: 0.85;
}

a[data-tooltip]::before {
    /*
   * using data-tooltip instead of title so we
   * don't have the real tooltip overlapping
   */
    content: attr(data-tooltip);
    background: #000;
    color: #fff;
    font-size: 13px;
    padding: 5px;
    border-radius: 5px;
    /* we don't want the text to wrap */
    white-space: nowrap;
    text-decoration: none;
}

a[data-tooltip]::after {
    width: 0;
    height: 0;
    border: 6px solid transparent;
    content: '';
}

a[data-tooltip]:hover::before,
a[data-tooltip]:hover::after {
    display: block;
}

/** positioning **/
/* top tooltip */
a[data-tooltip][data-placement="top"]::before {
    bottom: 100%;
    left: 0;
    margin-bottom: 40px;
}

a[data-tooltip][data-placement="top"]::after {
    border-top-color: #000;
    border-bottom: none;
    bottom: 50px;
    left: 20px;
    margin-bottom: 4px;
}

#calendar {
    max-width: 900px;
    margin: 40px auto;
}

/*Über Uns*/
/*Vorstand Karten*/
.personen {
    display: flex;
    flex-wrap: wrap;
    /*padding: 1em;*/
    /*padding-bottom: 5em;*/
}

.flex-grid {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    /*padding: 1em;*/
    /*padding-bottom: 5em;*/
}

.justify-content-center {
    justify-content: center;
}

.mitglied {
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    max-width: 150px;
    margin: auto;
    text-align: center;
    height: 250px;
}

.mitglied:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

/* unvisited link */
a:link .mitglied {
    color: black;
    text-decoration: none !important;
}

/* visited link */
a:visited .mitglied h4 {
    color: black;
    text-decoration: none !important;
}

/* mouse over link */
a:hover .mitglied h4 {
    color: black;
    text-decoration: none !important;
}

/* selected link */
a:active .mitglied h4 {
    color: black;
    text-decoration: none !important;
}

a:any-link .mitglied h4 {
    color: black;
    text-decoration: none !important;
}

.card-border-radius {
    border-radius: 12px;
}

.vorstand {
    padding: 1em;
}

#vorstand {
    margin-left: 5.5em;
}

.title {
    color: grey;
}

/*IMG Slider*/
.carousel {
    position: relative;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.carousel:hover .slide:after,
.carousel:hover .counter,
.carousel:hover .slide:before {
    opacity: 1;
}

.slide {
    float: right;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #eee;
    text-align: center;
    transition: opacity 0.4s;
    opacity: 1;
}

.slide:before {
    content: attr(annot);
    display: block;
    position: absolute;
    left: 20px;
    bottom: 20px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    font-weight: 300;
    z-index: 12;
    opacity: 0;
    transition: opacity 0.3s;
    text-shadow: 0 0 1px #000;
}

.slide:after {
    content: attr(slide);
    display: block;
    position: absolute;
    bottom: 0;
    transition: opacity 0.3s;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
    text-align: left;
    text-indent: 549px;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0 0 1px #000;

}

.slide img {
    width: 100%;
    position: absolute;
    top:-100%; left:0; right: 0; bottom:-100%;
    margin: auto;
}

.faux-ui-facia {
    top: 0;
    right: 0;
    float: right;
    position: absolute;
    margin-top: 0;
    z-index: 9;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.faux-ui-facia:checked {
    z-index: 8;
}

.faux-ui-facia:checked + .slide {
    opacity: 0;
}

.faux-ui-facia:checked:nth-child(1):checked {
    z-index: 9;
}

.faux-ui-facia:nth-child(1):checked {
    float: left;
    z-index: 9;
}

.faux-ui-facia:nth-child(1):checked + .slide {
    opacity: 1;
}

.faux-ui-facia:nth-child(1):checked ~ .faux-ui-facia {
    float: left;
    z-index: 8;
}

.faux-ui-facia:nth-child(1):checked ~ .faux-ui-facia + .slide {
    opacity: 0;
}

.faux-ui-facia:nth-child(1):checked ~ .faux-ui-facia:checked {
    z-index: 9;
}

.faux-ui-facia:nth-child(1):checked ~ .faux-ui-facia:checked + .slide {
    opacity: 1;
}

/* --- Intro text --- */
.intro {
    padding: 80px;
}

.intro__title {
    font-weight: 200;
    font-size: 32px;
    color: #fff;
}

.intro__body {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    opacity: 0.48;
    padding: 0 160px;
    margin: 0;
}

.mitglied h4 {
    font-family: initial !important;
    cursor: pointer;
}

#mitglieder .mitglied {
    margin-left: 2em;
    #margin-right: 2em;
    margin-bottom: 1em;
}

#vorstand .mitglied {
    margin-left: 2em;
    margin-bottom: 1em;
}

.var_dump {
    background-color: white;
}

.borderless td, .borderless th {
    border: none;
}

.hide {
    display: none;
}
