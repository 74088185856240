.user-card {
    background-size: contain;
    border-radius: 10px;
    box-shadow: 0 10px 30px -5px rgba(60, 60, 60, 0.2);
    text-align: center;
    padding: 30px;
    min-width: 240px;
    max-width: 240px;
    height: 280px;
    margin: auto;
}

.grid-card {
    margin: unset;
    margin-left: 1em;
    margin-bottom: 1em;
}

.user-card img {
    background-color: #dddfe6;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin: 1em auto;
    display: block;
    object-fit: cover;
}
/*.card h1 {
    font-size: 22px;
    margin: 10px auto 0;
    letter-spacing: 1px;
}*/
.user-card h2 {
    margin: auto;
    font-weight: 300;
    font-size: 35px !important;
}

.user-card .button {
    display: block;
    text-decoration: none;
    background: #3b70fc;
    color: #f9f9f9;
    padding: 12px;
    border: none;
    border-radius: 25px;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 700;
    min-width: 200px;
    transition: 0.2s;
}

.user-card .button span {
    font-size: 20px;
    line-height: 1;
    vertical-align: top;
}

.user-card .button:hover {
    background: #1856fb;
}

.user-card:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.flex-grid-member {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
